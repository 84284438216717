/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

h1, h2 {
    color: #5E6271;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.logo {
    max-width: 530px;
    width: calc(100vw - 20px);
    height: 122px;
    margin: 30px auto 20px auto;
    background-image: url("./images/Logo.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 0;
}

.bold {
    font-weight: 600;
    text-align: center;
}

.light {
    font-weight: 200;
    text-align: center;
}
